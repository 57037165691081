import { useNavigate } from 'react-router-dom';
import { Contents } from './Data';
import './Popup.css';
import useQuery from "./useQuery";

function Popup() {
  const navigate = useNavigate();
  let query = useQuery();

  let key = query.get("popup")

  if (key && key.length > 0) {
    let text = Contents.get(key)
    if (text) {
      return (
        <div className="PopupMain" onClick={(event) => {
          if (event.target === event.currentTarget) {
            navigate("?")
          }
        }}>
          <div className="PopupBack">
            <div className="PopupShadow"></div>
            <div className="PopupText">
              {/* TODO@ID actually sucks, this should be text somehow now... as I be concept should be able to put anything into the right thing... right? */}
              {text.right}<br/>
            </div>
          </div>
          {/* TODO@ID this should be an icon so the curser stays nice, or just a navLink or something? */}
          <div className='PopupX' onClick={() => navigate("?")}>X</div>
        </div>
      );
    }
  }
  return null
}

export default Popup;
