import { TwitchPlayer } from "react-twitch-embed";
import CardContent from "./CardContent"
import CardText from "./CardText";
import PersistNavLink from "./PersistNavLink";
import { TimeHelper } from './TimeHelper';

// images
import logo from './logo.svg';
import WeekImg from './Week.svg';
import PlanImg from './Plan.svg';
import SCCImg from './SCC.svg';
import BTCHImg from './BTCH.svg';
import BRBImg from './BRB.svg';
import Cpp20Img from './Cpp20.svg';

const streamTime: number = 15

export const Contents: Map<string, CardContent> = new Map();


export const HomeContent: CardContent[] = [];

const stream: CardContent = {
  title: "Stream",
  left: <TwitchPlayer channel="warmyone" muted={true} autoplay={true} width="100%" height="100%" />,
  right: <CardText textKey="Stream">Hey, glad you found my site! I am a fairly new streamer on Twitch. Most of the time programming tools for other streamers and myself. If you like to, come hang out at <a target="_blank" rel="noreferrer" href="https://www.twitch.tv/warmyone">twitch.tv/warmyone</a>.</CardText>,
  horizontalSplit: 60,
  verticalSplit: 66,
}
HomeContent.push(stream);
Contents.set("Stream", stream);

const schedule: CardContent = {
  title: "Schedule",
  left: <img src={WeekImg} alt='Week' />,
  right: <CardText textKey="Schedule">I will be live every Friday and Sunday starting latest at {streamTime}:00 {TimeHelper.getEUZoneStr()}. This should be {TimeHelper.makeLocalTime(streamTime)}:00 ({TimeHelper.makeDummTime(TimeHelper.makeLocalTime(streamTime))}) in your timezone. If I cannot make it I will announce it on stream and adjust my schedule at <a target="_blank" rel="noreferrer" href="https://www.twitch.tv/warmyone/schedule">twitch.tv/warmyone/schedule</a>.</CardText>,
  horizontalSplit: 40,
  verticalSplit: 34,
}
HomeContent.push(schedule);
Contents.set("Schedule", stream);

const plan: CardContent = {
  title: "Plan",
  left: <img src={PlanImg} alt='Plan' />,
  right: <CardText textKey="Plan">Most streams will start with some StackOverflow grinding, foremost to improve upon some interesting questions, but as well to share some of my gained knowledge.Mainly I am programming tools for Twitch or learn some new languages and frameworks. Otherwise I will occasionally work on any creative idea I come up with. If you are interested in my planned projects, just click <PersistNavLink to={"/Projects"}>HERE</PersistNavLink>. Besides that I play some MtG Arena every few hours when I lose focus. Same again, occasionally I will also play some other games on Stream.</CardText>,
  horizontalSplit: 40,
  verticalSplit: 34,
}
HomeContent.push(plan);
Contents.set("Plan", plan);


export const ProjectsContent: CardContent[] = [];

const SCC: CardContent = {
  title: "Codename SCC",
  left: <img src={SCCImg} alt='SCC' />,
  right: <CardText textKey="SCC">This tool goes under the codename SCC and shall monitor the entirety of a chosen stream. Everything form chat, view count, subs, donations, to maybe some day even text extraction of the streams audio. On the other hand the tool should be able to interact with and trigger common other tools, like OBS.
    <br /><br />The goal is to collect all information possible into one place and give the ability to freely process them and trigger various actions. Therefore the tool is split into inputs and outputs that can be wired in any combination. In the simplest way a chat input could be compared to "!command" and be wired to trigger a popup in OBS.
    <br /><br />The processing and triggering of outputs will be fully customizable, falling back to the possibility to script extensions, giving limitless options. Everything can be achieved from sending chat messages to starting other programs.
    <br /><br />Another key aspect is a persistent currency system per user with which every element can interact with, manipulate and output it.
    <br /><br />Monitoring a certain Stream, all information collected, will be held for later use. As well as the option to collect all past information still available. I have not yet decided on the language to use, and might just try some and see which I like most.</CardText>,
  horizontalSplit: 40,
  verticalSplit: 34,
}
ProjectsContent.push(SCC);
Contents.set("SCC", SCC);

const BTCH: CardContent = {
  title: "Codename BTCH",
  left: <img src={BTCHImg} alt='BTCH' />,
  right: <CardText textKey="BTCH">Inspired by tweetdeck, I want this tool, going by the codename BTCH, to create an alternative chat interface focused on grouping and structuring the messages instead of just one feed. It shall connect to SCC to have the current and past chats available.
    <br /><br />A fully customizable UI will allow for a personally appealing look. Every box can have its own ruling on how and which chat messages to show. One example would be to show all messages of a selected user for a certain amount of time or number of messages. You will never miss a response of any Chatter, with just one click on their name. Another example is to show all messages containing specific keywords.
    <br /><br />Also things like combining similar messages into one but highlighting it more if it is posted more often are possible. This can remove visual distraction and lets you miss less messages. The message appearance can also be adjusted, to, for example, show the twitch profile pictures and more.
    <br /><br />Future iterations may include a voting system and auto translation of messages in foreign languages. Most likely this will be my go to project to try out flutter.</CardText>,
  horizontalSplit: 40,
  verticalSplit: 34,
}
ProjectsContent.push(BTCH);
Contents.set("BTCH", BTCH);

const BRBeast: CardContent = {
  title: "Codename BRBeast",
  left: <img src={BRBImg} alt='BRBeast' />,
  right: <CardText textKey="BRBeast">Utilizing SCC this web app under the codename BRBeast focuses an creating an interactive Pause-/BRB-Screen. There are several features I will explore with this project. From showing infos and highlights from any other source, over popping clips to be shown on screen, to playing games. All controlled by chat messages.
    <br /><br />As this tool is planned to be used as a web source in OBS and I am decent in React, I will choose it as the programming language, here. Except if I fall in love with flutter in another project and want use it even more.</CardText>,
  horizontalSplit: 40,
  verticalSplit: 34,
}
ProjectsContent.push(BRBeast);
Contents.set("BRBeast", BRBeast);


export const LearningContent: CardContent[] = [];

const cpp20: CardContent = {
  title: "C++20",
  left: <img src={Cpp20Img} alt='Cpp20' />,
  right: <CardText textKey="cpp20">I have some years of experience in C++, but have not yet looked into C++20 in detail. Therefore I am doing a self teaching course on stream, to learn the new features and experiment with them. Especially Modules look like an interesting concept I want to try out. Any advice and input is more than welcome, feel free to come over and hang out.
    <br /><br />In the near future I also want to look into Rust and flutter, we'll see when I get to that.</CardText>,
  horizontalSplit: 40,
  verticalSplit: 34,
}
LearningContent.push(cpp20);
Contents.set("cpp20", cpp20);
