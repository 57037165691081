import './CardContainer.css';
import CSS from 'csstype';
import { animated } from 'react-spring';
import CardContent from './CardContent';
import { useLayoutEffect, useRef, useState } from 'react';
import useWindowDimensions from './windowDimension';

interface CardContainerProps {
  readonly leftTransStyle: any; //TODO@ID
  readonly rightTransStyle: any; //TODO@ID
  readonly isOdd: boolean;
  readonly content: CardContent;
}

function CardContainer({ isOdd, leftTransStyle, rightTransStyle, content }: CardContainerProps) {
  const { width, height } = useWindowDimensions();

  const [dimension, setDimension] = useState({ width: 0, height: 0 })
  let self = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (self.current) {
      setDimension({ width: self.current.clientWidth, height: self.current.clientHeight });
    }
  }, [width, height])

  let cardStyle: CSS.Properties = {};
  let cardOrientationStyle: CSS.Properties = {};
  let leftDivStyle: CSS.Properties = {};
  let rightDivStyle: CSS.Properties = {};
  let leftAnimStyle: CSS.Properties = {};
  let rightAnimStyle: CSS.Properties = {};
  if (dimension.width / dimension.height > 1) {
    // horizontal
    cardStyle.width = '70%'
    cardStyle.height = '70%'
    cardStyle.top = '150px'
    cardOrientationStyle.flexDirection = 'row';
    leftAnimStyle.width = content.horizontalSplit + '%';
    leftAnimStyle.height = '100%';
    rightAnimStyle.width = (100 - content.horizontalSplit) + '%';
    rightAnimStyle.height = '100%';
    leftDivStyle.flexDirection = 'column';
    leftDivStyle.width = '80%';
    leftDivStyle.height = '80%';
    leftDivStyle.top = '10%';
    leftDivStyle.right = '2.5%';
    rightDivStyle.width = '85%';
    rightDivStyle.height = '75%';
    rightDivStyle.top = '15%';
    rightDivStyle.left = '2.5%';
  } else {
    // vertical
    cardStyle.width = '80%'
    cardStyle.height = '80%'
    cardStyle.top = '150px'
    cardOrientationStyle.flexDirection = 'column';
    leftAnimStyle.width = '100%';
    leftAnimStyle.height = content.verticalSplit + '%';
    rightAnimStyle.width = '100%';
    rightAnimStyle.height = (100 - content.verticalSplit) + '%';
    leftDivStyle.flexDirection = 'row';
    leftDivStyle.width = '80%';
    leftDivStyle.height = '80%';
    leftDivStyle.bottom = '2.5%';
    leftDivStyle.left = '7.5%';
    rightDivStyle.width = '80%';
    rightDivStyle.height = '85%';
    rightDivStyle.top = '2.5%';
    rightDivStyle.right = '7.5%';
  }

  return (
    <div className='CardContainer' ref={self}>
      <div className='Card' style={cardStyle}>
        <animated.div className='LeftBackAnim' style={leftTransStyle}>
        </animated.div>
        <animated.div className='RightBackAnim' style={rightTransStyle}>
        </animated.div>
        <div className='CardContent' style={cardOrientationStyle}>
          <animated.div className='LeftAnim' style={{ ...leftTransStyle, ...leftAnimStyle }}>
            <div className='LeftDiv' style={leftDivStyle}>
              {content.left}
              {/* TODO@ID do next line correctly */}
              <h6 style={{position: 'absolute', bottom: -40, right: 0}}>{content.title}</h6>
            </div>
          </animated.div>
          <animated.div className='RightAnim' style={{ ...rightTransStyle, ...rightAnimStyle }}>
            <div className='RightDiv' style={rightDivStyle}>
              {content.right}
            </div>
          </animated.div>
        </div>
      </div>
    </div>
  );
}

export default CardContainer;


// const evenCardStyle: CSS.Properties = {
//   // backgroundImage: 'url(./card.svg)',
//   // backgroundPosition: 'center',
//   // backgroundSize: 'cover',
//   // backgroundRepeat: 'no-repeat'
// };
// const oddCardStyle: CSS.Properties = {
//   // backgroundImage: 'url(./card.svg)',
//   // backgroundPosition: 'center',
//   // backgroundSize: 'cover',
//   // backgroundRepeat: 'no-repeat'
//   // backgroundColor: 'darkgray', //TODO@ID make this a prop.
// };
