import './Menu.css';
import useIsOverflow from './isOverflow';
import PersistNavLink from './PersistNavLink';
import { useRef } from 'react';

interface MenuItemProps {
  readonly icon: string;
  readonly text: string;
  readonly link: string;
  readonly extern: boolean;
}

function MenuItem({ icon, text, link, extern }: MenuItemProps) {
  // TODO@ID the overflow would need to be checkt for all menu buttons and turn all on/off, I think, else it would be wabbley, so I just removed the text for now...
  // const pRef = useRef<HTMLDivElement>(null);
  // const { horizontalOverflow } = useIsOverflow(pRef, [text]);

  /* <div className='MenuShadow'>
  </div> */
  const inner =
    //ref={pRef}
    <div className='MenuBack'>
      <img className='MenuIcon' src={icon} alt={text} />
      {/* {!horizontalOverflow && <div className='MenuText'>{text}</div>} */}
    </div>
  if (extern)
  {
    return (<a className='MenuItem' target="_blank" rel="noreferrer" href={link}>{inner}</a>)
  }
  else
  {
    return (<PersistNavLink className={({ isActive }) => isActive ? 'MenuItemSel' : 'MenuItem'} to={link}>{inner}</PersistNavLink>)
  }
}

interface MenuProps {
  readonly links: MenuItemProps[];
}

function Menu({ links }: MenuProps) {
  return (
    <div className='Menu'>
      {
        links.map((prop: MenuItemProps, index: number) => <MenuItem key={index} icon={prop.icon} text={prop.text} link={prop.link} extern={prop.extern} />)
      }
    </div>
  );
}

export default Menu;
