import { createContext, useState } from "react";

export interface ScrollStates {
  scrollStates: Map<string, number>;
  setScrollState: (path: string, state: number) => void;
}

export const ScrollStatesContext = createContext<ScrollStates>({ scrollStates: new Map<string, number>(), setScrollState: () => { console.warn("ATM INVALID TO CALL THE SCROLL STATE SETTER!") } })

export const ScrollStatesProvider = (props: React.PropsWithChildren) => {
  const setScrollState = (path: string, state: number) => {
    setScrollStatesState((prevState: ScrollStates) => {
      prevState.scrollStates.set(path, state);
      return {
        scrollStates: prevState.scrollStates,
        setScrollState: prevState.setScrollState
      }
    })
  }
  const [scrollStatesState, setScrollStatesState] = useState<ScrollStates>({ scrollStates: new Map<string, number>(), setScrollState });

  return (
    <ScrollStatesContext.Provider value={scrollStatesState}>
      {props.children}
    </ScrollStatesContext.Provider>
  )
}

export default ScrollStatesContext;
