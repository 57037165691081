import { useState, useLayoutEffect } from 'react'
import useWindowDimensions from './windowDimension';

// SOURCE: https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
// USAGE:
// import { useIsOverflow } from './useIsOverflow';
// const App = () => {
//   const ref = useRef();
//   const isOverflow = useIsOverflow(ref, (isOverflowFromCallback) => {
//     console.log(isOverflowFromCallback);
//   });
//   console.log(isOverflow);
//   return (
//     <div style={{ overflow: 'auto', height: '100px' }} ref={ref}>
//       <div style={{ height: '200px' }}>Hello React</div>
//     </div>
//   );
// };

const useIsOverflow = (ref: React.RefObject<HTMLDivElement>, depends: React.ReactNode[], callback?: Function) => {
  const { width, height } = useWindowDimensions();
  const [verticalOverflow, setVerticalOverflow] = useState<boolean>();
  const [horizontalOverflow, setHorizontalOverflow] = useState<boolean>();

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = (current: HTMLDivElement) =>
    {
      const verticalOverflow = current.scrollHeight > current.clientHeight;
      setVerticalOverflow(verticalOverflow);

      const horizontalOverflow = current.scrollWidth > current.clientWidth;
      setHorizontalOverflow(horizontalOverflow);

      if (callback) {
        callback({ verticalOverflow, horizontalOverflow });
      }
    };

    if (current) {
      trigger(current);
    }
  }, [callback, ref, ...depends, width, height]);

  return { verticalOverflow, horizontalOverflow };
};

export default useIsOverflow
