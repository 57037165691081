import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSpring } from 'react-spring';
import CardContainer from './CardContainer';
import CardContent from './CardContent';
import './CardDeck.css';
import ScrollStatesContext from './ScrollStates';
import Sidebar from './Sidebar';

interface CardDeckProps {
  readonly content: CardContent[];
}

function CardDeck({ content }: CardDeckProps) {
  // used to restore scroll states when revisiting a page
  const scrollStates = useContext(ScrollStatesContext);
  const location = useLocation();
  // used to read scroll state of this component
  const contentContainerRef = React.createRef<HTMLDivElement>();

  // represents the scroll state for odd and even Cards rendered by this component
  const [scrollStateSpring, scrollStateSpringApi] = useSpring(() => ({
    oddState: 0.0,
    evenState: 1.0,
    cardIndex: 0.0,
    delay: 0
  }))
  // style for odd Cards
  const [oddLeftTransStyles, oddLeftTransStylesApi] = useSpring(() => ({
    opacity: 1.0,
    transform: 'translateX(0%)',
    delay: 0
  }));
  const [oddRightTransStyles, oddRightTransStylesApi] = useSpring(() => ({
    opacity: 1.0,
    transform: 'translateX(0%)',
    delay: 0
  }));
  // style for even Cards
  const [evenLeftTransStyles, evenLeftTransStylesApi] = useSpring(() => ({
    opacity: 1.0,
    transform: 'translateX(0%)',
    delay: 0
  }));
  const [evenRightTransStyles, evenRightTransStylesApi] = useSpring(() => ({
    opacity: 1.0,
    transform: 'translateX(0%)',
    delay: 0
  }));

  // calculate all Springs
  const onScroll = useCallback((event: Event) => {
    const currentTarget = event.currentTarget as HTMLDivElement
    const yOffset = currentTarget.scrollTop;
    const clientHeight = currentTarget.clientHeight;

    // produces relative values between 0 and 2
    // == 1, stable position
    // < 1, before stable position
    // > 1, after stable position
    scrollStateSpringApi.set({
      oddState: yOffset / clientHeight % 2,
      evenState: Math.abs((yOffset / clientHeight - 1) % 2),
      cardIndex: yOffset / clientHeight
    })

    const stateTriggers = [0, 0.7, 1.0, 1.3, 2];
    const opacityStates = [1, 1, 1, 1, 1];
    // const opacityStates = [0, 1, 1, 0, 0];
    const transformLeftStates = ['translateX(-50%)', 'translateX(-0%)', 'translateX(0%)', 'translateX(-50%)', 'translateX(-50%)'];
    const transformRightStates = ['translateX(+50%)', 'translateX(0%)', 'translateX(0%)', 'translateX(+50%)', 'translateX(+50%)'];

    oddLeftTransStylesApi.start({
      opacity: scrollStateSpring.oddState.to(stateTriggers, opacityStates),
      transform: scrollStateSpring.oddState.to(stateTriggers, transformLeftStates),
    });
    oddRightTransStylesApi.start({
      opacity: scrollStateSpring.oddState.to(stateTriggers, opacityStates),
      transform: scrollStateSpring.oddState.to(stateTriggers, transformRightStates),
    });
    evenLeftTransStylesApi.start({
      opacity: scrollStateSpring.evenState.to(stateTriggers, opacityStates),
      transform: scrollStateSpring.evenState.to(stateTriggers, transformLeftStates),
    });
    evenRightTransStylesApi.start({
      opacity: scrollStateSpring.evenState.to(stateTriggers, opacityStates),
      transform: scrollStateSpring.evenState.to(stateTriggers, transformRightStates),
    });
  }, [scrollStateSpring, scrollStateSpringApi, evenLeftTransStylesApi, oddLeftTransStylesApi, evenRightTransStylesApi, oddRightTransStylesApi]);

  useEffect(() => {
    const current = contentContainerRef.current;
    current?.addEventListener('scroll', onScroll);
    return () => {
      current?.removeEventListener('scroll', onScroll)
    }
  }, [onScroll, contentContainerRef])

  useEffect(() => {
    const state = scrollStates.scrollStates.get(location.pathname)
    if (contentContainerRef.current) {
      contentContainerRef.current.scrollTo({ top: state === undefined ? 0 : state, behavior: 'auto' });
    }
  }, [scrollStates, location, contentContainerRef])

  return (
    <div id='cardDeck' className='CardDeck' ref={contentContainerRef}>
      {content.map((elem: any, index: number) => {
        let leftTransStyle;
        let rightTransStyle;
        let isOdd: boolean;
        if (index % 2 !== 0) {
          leftTransStyle = oddLeftTransStyles;
          rightTransStyle = oddRightTransStyles;
          isOdd = true;
        } else {
          leftTransStyle = evenLeftTransStyles;
          rightTransStyle = evenRightTransStyles;
          isOdd = false;
        }
        return <CardContainer key={index} content={elem} isOdd={isOdd} leftTransStyle={leftTransStyle} rightTransStyle={rightTransStyle}/>;
      })}
      {content.length > 1 && <Sidebar content={content} amount={content.length} highlight={scrollStateSpring} />}
    </div>
  );
}

export default CardDeck;
