
import { Route, Routes } from 'react-router-dom';
import './App.css';
import CardDeck from './CardDeck';
import {HomeContent, LearningContent, ProjectsContent} from './Data';
import Menu from './Menu';
import Popup from './Popup';

// Icons
import Home from './Home.svg';
import Projects from './Projects.svg';
import Learn from './Learn.svg';
import Twitch from './Twitch.svg';
import Gitlab from './Gitlab.svg';

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<CardDeck content={HomeContent} />} />
        <Route path="/Home" element={<CardDeck content={HomeContent} />} />
        <Route path="/Projects" element={<CardDeck content={ProjectsContent} />} />
        <Route path="/Learning" element={<CardDeck content={LearningContent} />} />
        <Route path="*" element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }/>
      </Routes>
      <Menu links={[
        { icon: Home, text: 'Home', link: '/Home', extern: false },
        { icon: Projects, text: 'Projects', link: '/Projects', extern: false },
        { icon: Learn, text: 'Learning', link: '/Learning', extern: false },
        { icon: Twitch, text: 'Twitch↪', link: 'https://twitch.tv/warmyone', extern: true },
        { icon: Gitlab, text: 'Gitlab↪', link: 'https://gitlab.com/Warmy', extern: true },
      ]} />
      <Popup/>
    </div>
  );
}

export default App;

//NOT TOO MAD, BUT, HMM:
// TODO@ID I somehow fucked the scrolling up.... is it some timing thing? that will be annoying to fix.
// -> actually I did not fuck that up, chrome update did. I will have to implement that functionality via springs at some point, listening to the scroll event and then trigger scroll to next pos after a timer. (Should not be to hard, actually)

//SUGAR:
// TODO@ID make the text bigger, when the screen size is big (so, when not on phone)

// TODO@ID maybe add side scroll to switch between pages?

// TODO@ID In distant future there I could do more than just text and give scroll through details, but hmmm.

//TODO@ID for the PersistNavLink: look up how to transfer all props and not just manually