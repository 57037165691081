import { useRef } from 'react';
import './CardText.css';
import useIsOverflow from './isOverflow';
import PersistNavLink from './PersistNavLink';

interface CardTextProps {
  readonly textKey: string;
  readonly callback?: Function;
}

function CardText({ textKey, children, callback }: React.PropsWithChildren<CardTextProps>) {
  const pRef = useRef<HTMLDivElement>(null);
  const { verticalOverflow } = useIsOverflow(pRef, [children], callback);

  const moreButton = <PersistNavLink className='MoreButton' to={"?popup=" + textKey}><div className='MoreBack'><div className='MoreText'>MORE</div></div></PersistNavLink>;

  return (
    <div className='CardTextContainer' ref={pRef}>{verticalOverflow && moreButton}{children}</div>
  );
}

export default CardText;
