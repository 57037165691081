import './Sidebar.css';
import { animated } from 'react-spring';
import CardContent from './CardContent';
import useWindowDimensions from './windowDimension';

interface SidebarItemProps {
  readonly highlighted: any; //TODO@iD
  readonly indexI: number;
}

function SidebarItem({ highlighted, indexI }: SidebarItemProps) {
  const { height } = useWindowDimensions();

  return (
    <animated.div className="SidebarItem" style={{
      width: highlighted.cardIndex.to((cI: any) => cI - indexI).to([-1000, -1, 0, 1, 1000], [50, 50, 100, 50, 50]).to((cI: any) => `${cI}%` ),
      height: highlighted.cardIndex.to((cI: any) => cI - indexI).to([-1000, -1, 0, 1, 1000], [50, 50, 100, 50, 50]).to((cI: any) => `${cI}%`)
    }} onClick={() => {
      const cardDeck = document.getElementById('cardDeck');
      if (cardDeck) {
        cardDeck.scrollTo({ top: height * indexI, behavior: 'smooth' });
      }
    }  }>
    </animated.div>
  );
}

interface SidebarProps {
  readonly content: CardContent[];
  readonly amount: number;
  readonly highlight: any; //TODO@ID
}

function Sidebar({ amount, highlight, content }: SidebarProps) {

  // let elems: React.ReactNode[] = []
  // for (let i: number = 0; i < amount; i++)
  // {
    // console.warn(i + " . " + highlight.cardIndex)
    // console.warn(i < (highlight.cardIndex + 1) && i > (highlight.cardIndex - 1))
    // elems.push(<SidebarItem key={i} highlighted={highlight.cardIndex > 0}></SidebarItem>) //i < (highlight.cardIndex + 1) && i > (highlight.cardIndex - 1)
  // }

  // const [sidebarItemStyles, sidebarItemStylesApi] = useSpring(() => ({
  //   width: '17px',
  //   height: '50%'
  // }));
  // sidebarItemStylesApi.start({
  //   width: highlight.cardIndex.to([0, 1, 2], ['17px', '25px', '30px']),
  //   height: highlight.cardIndex.to([0, 1, 2], ['50%', '75%', '100%']),
  // });

  return (
    <animated.div className="Sidebar">
      {content.map((card: CardContent, i: number) => {
        return <SidebarItem key={i} indexI={i} highlighted={highlight}></SidebarItem>
      })}
    </animated.div>
  );
}

export default Sidebar;
