export class TimeHelper {
  public static lastSunday(year: number, month: number): Date {
    // https://stackoverflow.com/a/63455760/9909548
    var date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    date.setDate(date.getDate() - dayDiff);
    return date;
  }
  public static isEUSummerTime(): boolean {
    // https://discourse.julialang.org/t/how-to-check-if-date-is-summer-time/75950/3
    const today = new Date()
    const lastSundayMarch = this.lastSunday(today.getFullYear(), 3)
    const lastSundayOctober = this.lastSunday(today.getFullYear(), 10)
    // inaccurate as the time of the day is ignored
    return today > lastSundayMarch && today < lastSundayOctober;
  }
  public static getEUZoneStr(): string {
    return this.isEUSummerTime() ? "CEST" : "CET"
  }
  public static todayLocalZoneOffset(): number {
    let today: Date = new Date()
    today.setUTCHours(12)
    return today.getTimezoneOffset()
  }
  public static makeLocalTime(euZoneHour: number) {
    let utcHour
    if (this.isEUSummerTime()) {
      utcHour = euZoneHour - 2
    }
    else {
      utcHour = euZoneHour - 1
    }
    if (utcHour < 0) {
      utcHour += 24
    }
    const offset = this.todayLocalZoneOffset()
    let localeHour = utcHour - (offset / 60)
    if (localeHour > 23) {
      localeHour -= 24
    }
    else if (localeHour < 0) {
      localeHour += 24
    }
    return localeHour
  }
  public static makeDummTime(localeHour: number): string {
    if (localeHour === 0 || localeHour === 24) {
      return "12 a.m."
    }
    else if (localeHour < 12) {
      return localeHour.toString() + " a.m."
    }
    else if (localeHour === 12) {
      return "12 p.m."
    }
    else if (localeHour > 12) {
      return (localeHour - 12).toString() + " p.m."
    }
    return "ERROR"
  }
}
