import { useContext } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom"
import ScrollStatesContext from "./ScrollStates";

const PersistNavLink = (props: React.PropsWithChildren<NavLinkProps>) => {
  const location = useLocation();
  const scrollStates = useContext(ScrollStatesContext);

  return (
    <NavLink
      className={props.className}
      to={props.to}
      onClick={() => {
        let state = document?.getElementById('cardDeck')?.scrollTop
        scrollStates.setScrollState(location.pathname, state === undefined ? 0 : state)
      }}
    >
      {props.children}
    </NavLink>
  )
}

export default PersistNavLink;
